import React from 'react';
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Container } from "../components/Container"
import {Hero} from "../components/slices";
import {AboutBackground} from "../components/MainBackground/AboutBackground";
import SlicesRenderer from "../components/SlicesRenderer";
import {focusAreas} from "../config/navigation";
import MyLink from "../utils/checkExternalLink";


const AboutUsTemplate = ({data, location}) => {
  const page = data.prismicAbout.data;
  const pageData = data.prismicAbout.dataRaw;
  const headerLinks = data.allPrismicLayout.edges[0].node.dataRaw.header_navigation;
  const headerNavigationList = headerLinks.map(item =>
    item.sub_links === "focus_areas" ? { ...item, sub_links: focusAreas } : item
  )
  return (
    <Layout location={location}>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
      </style>
      <Seo
        pathname={location.pathname}
        title={page.meta_title}
        description={page.meta_description}
      />
      <Hero />
      <AboutBackground type={"violet"} container>
        <Container>
          <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: page.title.html }}/>
          </Wrapper>
        </Container>
        <AboutContainer>
          <Container variant="content">
            <AboutContent dangerouslySetInnerHTML={{ __html: page.about_text.html }}/>
          </Container>
        </AboutContainer>
      </AboutBackground>
      <MenuWrapper>
        <Container>
          <MainMenu>
            {headerNavigationList.map((item, i) => {
              const locationCurrent =
                location?.pathname?.split("/")[1]
              const isCurrentLocation = !locationCurrent
                ? `/${locationCurrent}` === item.link_url
                : item?.link_url?.includes(locationCurrent)

              return !item.sub_links ? (
                <li
                  key={i}
                  className={
                    isCurrentLocation
                      ? "underline"
                      : locationCurrent === "webinar" &&
                      item.link_url === "/event"
                        ? "underline"
                        : ""
                  }
                >
                  <MyLink href={item.link_url}>{item.label}</MyLink>
                </li>
              ) : (
                <li className="subLink" key={i}>
                  <span>{item.label}</span>
                  <ul>
                    {item.sub_links?.map((item, i) => {
                      const locationCurrent =
                        location?.pathname?.split("/")[2]
                      const isCurrentLocation =
                        `${locationCurrent}` ===
                        item.link_url.split("/")[2]
                      return (
                        <li
                          key={i}
                          className={
                            isCurrentLocation ? "underline" : ""
                          }
                        >
                          <MyLink href={item.link_url}>
                            {item.label}
                          </MyLink>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </MainMenu>
        </Container>
      </MenuWrapper>
      <SlicesRenderer slices={pageData.body}/>
    </Layout>
  );
};

AboutUsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutUsTemplate;

export const query = graphql`
  query {
  prismicAbout {
    data {
      title {
        html
      }
      meta_title
      meta_description
      about_text {
        html
        text
      }
    }
    dataRaw
  }
  allPrismicLayout {
    edges {
      node {
        id
        dataRaw
      }
    }
  }
}`


const AboutContainer = styled.div`
  background: rgb(249, 249, 249);
  padding-bottom: 5px;
  padding-top: 20px;
  .gatsby-image-wrapper {
    display: block;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  .block-img {
    display: block;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  iframe {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 0 auto;
    display: block;
  }
  a[type="button"] {
    margin-bottom: 20px;
  }
  * {
    font-family: Roboto, sans-serif !important;
  }
  p {
    margin-bottom: 32px;
    margin-top: 0;
    color: rgb(62, 62, 62);
    font-weight: 500!important;
    font-size: 18px;
  }
  @media (max-width: 540px) {
    iframe {
      height: 300px;
    }
  }
  @media (max-width: 599px) {
    p {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 15px;
    }
    * {
      margin-bottom: 15px;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 20px;

  h1 {
    color: #fff;
    font-size: 39px;
    text-transform: uppercase;
    line-height: calc(78 / 64);
    margin-bottom: 0;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    @media (max-width: 599px) {
      font-size: 25px;
      line-height: 28px;
    }
  }
  p {
    a {
      text-decoration: underline;
      transition: 0.3s;
      &:hover {
        color: ${({ theme }) => theme.colors.primary_color};
      }
    }
  }
`
const AboutContent = styled.div`
  margin-bottom: 32px;
  margin-top: 0;
  color: rgb(62, 62, 62);
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 1.8;
  a {
    color: cornflowerblue;
  }
  ul {
    margin-bottom: 30px;
    list-style: inside;
    margin-left: 20px;
  }
  h4 {
    font-size: 20px;
  }
  @media (max-width: 599px) {
    ul {
      margin-bottom: 20px;
      margin-left: 20px;
      font-size: 16px;
      li {
        margin-bottom: 0;
      }
    }
  }
`
const MainMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 16px 0;
  justify-content: space-between;
  color: white;
  list-style: none;

  & > li {
    position: relative;
    margin: 8px 15px 0 0;
    padding: 10px 15px;
    border: 1px solid white;
  }

  & > li:hover {
    transition: 0.5s ease;
    background: rgb(48, 38, 82);
  }

  & > li:last-child {
    margin-right: 0;
  }

  & > li span {
    cursor: pointer;
  }

  & > li:hover span + ul {
    display: block;
    min-width: 200px;
    position: absolute;
    left: 0;
    top: 41px;
    z-index: 10;
    background: #473974;
    color: white;

    li {
      padding: 10px;
    }
  }

  & > li ul {
    display: none;
  }

  @media (max-width: 1180px) {
    display: none;
  }
`
const MenuWrapper = styled.div`
  background: #473974;
`